import React, { useEffect, useRef, useState } from 'react';
import {
  CloseButton,
  Combobox,
  ComboboxChevron,
  ComboboxOptionProps,
  Loader,
  ScrollArea,
  TextInput,
  TextInputProps,
  useCombobox,
} from '@mantine/core';

export type TypeaheadPropsType = Omit<TextInputProps, 'onSelect'> & {
  onTextChange?: (text: string) => void;
  onSelect: (value: any) => void;
  onDeselect?: () => void;
  value?: string;
  data: {
    id: number;
    label: string;
    value: any;
    sticky?: boolean;
  }[];
  autofocus?: boolean;
  waitForKeyPress?: boolean;
  isLoading?: boolean;
  className?: string;
};

export const Typeahead = ({
  onTextChange,
  onSelect,
  onDeselect,
  value,
  data,
  autofocus,
  waitForKeyPress,
  isLoading,
  className,
  ...props
}: TypeaheadPropsType) => {
  const [hiddenValue, setHiddenValue] = useState<string | undefined>(value);
  const displayedValue = onTextChange ? value : hiddenValue;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [autofocus]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    combobox.resetSelectedOption();
    combobox.openDropdown();
    const text = event.currentTarget?.value;
    onTextChange ? onTextChange(text) : setHiddenValue(text);
  };

  const handleSelect = (label: string, option: ComboboxOptionProps) => {
    combobox.resetSelectedOption();
    combobox.openDropdown();
    const matchingOption =
      data.find((o) => option.key === o.id) || data.find((o) => label === o.label);
    if (matchingOption) {
      onSelect(matchingOption.value);
    }
    setHiddenValue(label);
  };

  const handleDeselect = () => {
    combobox.closeDropdown();
    onDeselect && onDeselect();
    setHiddenValue('');
  };

  const RightSelect = () => {
    if (isLoading) {
      return <Loader size="xs" />;
    }
    if (displayedValue && onDeselect) {
      return (
        <CloseButton
          size="sm"
          onMouseDown={(event) => event.preventDefault()}
          onClick={handleDeselect}
        />
      );
    }
    return <ComboboxChevron />;
  };

  const optionElements = (data || [])
    .filter((o) => o.label.toLowerCase().indexOf((value || '').toLowerCase()) !== -1 || o.sticky)
    .map((o) => (
      <Combobox.Option ta="left" value={o.label} key={o.id}>
        {o.label}
      </Combobox.Option>
    ));

  return (
    <Combobox onOptionSubmit={handleSelect} withinPortal={false} store={combobox}>
      <Combobox.Target>
        <TextInput
          ref={inputRef}
          className={className}
          value={displayedValue}
          onChange={handleTextChange}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          rightSection={<RightSelect />}
          disabled={!data.length && !waitForKeyPress}
          {...props}
        />
      </Combobox.Target>
      <Combobox.Dropdown
        hidden={!optionElements.length || (waitForKeyPress && !displayedValue?.length)}
        onClick={() => combobox.closeDropdown()}
      >
        <ScrollArea.Autosize mah={200} type="scroll">
          <Combobox.Options>{optionElements}</Combobox.Options>
        </ScrollArea.Autosize>
      </Combobox.Dropdown>
    </Combobox>
  );
};
