import React, { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { CloseButton, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';

import { getCustomAgronomicProduct } from 'constants/prescription';

import { inputSearchKeys } from 'util/queryKeys';
import { DEBOUNCE } from 'util/request';
import { getString } from 'strings/translation';
import showToast from 'actions/toastActions';
import { requestGetProductSearch } from 'store/cropPlans/requests';
import { AgronomicProductType } from 'store/cropPlans/types';
import { Typeahead } from 'common/Components/Mantine/Typeahead';

import { RxSettingsStateType } from '../SinglePrescription/Settings';

import { PrescriptionStateType } from './Container';

interface ProductSearchProps {
  defaultProduct: AgronomicProductType | null;
  language: string;
  onSave?: VoidFunction;
  rxForm: UseFormReturnType<PrescriptionStateType> | UseFormReturnType<RxSettingsStateType>;
}

const ProductSearch = ({ defaultProduct, language, onSave, rxForm }: ProductSearchProps) => {
  const { agronomicProduct, crop } = rxForm.getValues();

  const [productName, setProductName] = useState(agronomicProduct?.name || '');
  const [debouncedProductName] = useDebouncedValue(productName, DEBOUNCE);

  const { data, refetch } = useQuery<AgronomicProductType[], Error>({
    queryKey: inputSearchKeys.productSearch(productName, crop),
    queryFn: async (): Promise<AgronomicProductType[]> => {
      try {
        if (!debouncedProductName) {
          return [];
        }
        return await requestGetProductSearch(debouncedProductName, crop);
      } catch (err) {
        showToast(getString('failedToFetchProducts', language), 'error');
        throw err;
      }
    },
    enabled: !!productName,
  });

  // Trigger API immediately on first keystroke, then rely on debounce
  useEffect(() => {
    if (productName) {
      refetch();
    }
  }, [debouncedProductName]);

  const products: AgronomicProductType[] = Array.isArray(data) ? data : [];

  const productsWithCustom = [getCustomAgronomicProduct(language)].concat(products);

  const options = (productsWithCustom ?? []).map((s) => ({
    id: s.id,
    label: s.name,
    value: s.id,
    sticky: true,
  }));

  const updateField = <T,>(
    form: UseFormReturnType<PrescriptionStateType> | UseFormReturnType<RxSettingsStateType>,
    key: keyof T,
    value: T[keyof T],
  ): void => {
    (form.setFieldValue as (key: keyof T, value: T[keyof T]) => void)(key, value);
  };

  const reset = () => {
    updateField(rxForm, 'agronomicProduct', null);
    setProductName('');
  };

  const handleSelect = (value: string) => {
    const optionId = parseInt(value, 10);
    const item = (productsWithCustom ?? []).find((p) => p.id === optionId);
    if (item) {
      updateField(rxForm, 'agronomicProduct', item);
      setProductName(item.name);
      if (onSave) {
        onSave();
      }
    }
  };

  return (
    <>
      <Text>{getString('input', language)}</Text>
      <Typeahead
        onBlur={() => setProductName(agronomicProduct?.name || productName)}
        data={options}
        disabled={Boolean(agronomicProduct) && !defaultProduct}
        leftSection={<FiSearch />}
        onSelect={handleSelect}
        onTextChange={setProductName}
        placeholder={`${getString('input', language)}...`}
        rightSection={
          <CloseButton onClick={reset} onMouseDown={(e) => e.preventDefault()} size="sm" />
        }
        value={productName || defaultProduct?.name}
      />
    </>
  );
};

export default ProductSearch;
